import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 45.6917 45.15 C 48.5917 46.1 50.6917 48.95 51 52 C 51 58 51 61 51 61 L 28.5417 61 L 28.5417 30.3 L 39.2917 30.3 C 45.6917 30.3 49.6917 33.15 49.6917 38.65 C 49.6917 41.95 47.9417 44.35 45.6917 45.15 Z M 34 43 L 39 43 C 42.7917 43.2 44.6917 41.85 44.6917 38.95 C 44.6917 36.05 42.7917 34.8 39 35 L 34 35 L 34 43 Z M 34 48 L 34 61 L 46 61 C 46 57 46 55 46 52 C 45.5917 49.2 43.6917 47.5 40 47 L 34 47 Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
